export const credentialsModels = {
  ["6f9f0f92-06f5-4ab1-9bfd-145c7f27f2eb"]: null,
  ["4c60f035-b19e-4e97-87fe-fe0260faf663"]: null,
  ["71afea8c-7b50-4871-85eb-87ae66982e4d"]: {
    mid: { type: "text" },
    apiKey: { type: "text" },
    descriptorName: { type: "text" },
    descriptorUrl: { type: "text" },
  },
  ["41b589c8-677d-4ef6-91a0-fe188d3fd075"]: {
    companyNum: { type: "text" },
    secretKey: { type: "text" },
  },
  ["9c58ced7-e128-4b41-9828-232555fa513f"]: {
    apiKey: { type: "text" },
  },
  ["4269aa7c-cdae-423c-9030-0a794e0be240"]: {
    payoutCredential: {
      type: "object",
      child: {
        appId: { type: "text" },
        appKey: { type: "text" },
      },
    },
    payinCredentials: {
      type: "listOfObjects",
      child: {
        appId: { type: "text" },
        secretKey: { type: "text" },
        country: { type: "countrySelect" },
        enabled: { type: "bool" },
      },
    },
  },
  ["3b0d3fe0-5206-4640-82ad-333308a25780"]: {
    mid: { type: "text" },
    serverKey: { type: "text" },
    acquirers: {
      type: "listOfObjects",
      child: {
        domainName: { type: "text" },
        currencies: { type: "listOfCurrenciesTexts" },
        minScore: { type: "number" },
        maxScore: { type: "number" },
        enabled: { type: "bool" },
        isSecure: { type: "bool" },
      },
    },
  },
  ["d2336afc-1e8c-473b-8182-41cd3ed26529"]: {
    clientId: { type: "text" },
    clientSecret: { type: "text" },
  },
  ["5d8c9938-3d8e-42fe-8a06-76196b4ddc7b"]: {
    apiKey: { type: "text" },
    signatureKey: { type: "text" },
  },
  ["e60ebac2-3976-438b-a7ab-4fab89e646a4"]: {
    merchant: { type: "text" },
    callbackApiKey: { type: "text" },
    countryKeys: {
      type: "listOfObjects",
      child: {
        apiKey: { type: "text" },
        payoutBankCode: { type: "text" },
        country: { type: "countrySelect" },
        contractName: { type: "text" },
        enabled: { type: "bool" },
      },
    },
  },
  ["0063e91f-9b5b-444c-aa6f-ce5674db17ad"]: {
    externalMerchantId: { type: "text" },
    apiKey: { type: "text" },
  },
  ["0ea3bc80-eae0-4567-89fb-64ac7faee7f8"]: {
    // merchantName: { type: "text", size: "lg" },
    username: { type: "text", size: "md" },
    password: { type: "text", size: "md" },
    payoutCustomerId: { type: "text", size: "md" },
    payoutApiKey: { type: "text", size: "md" },
    accounts: {
      type: "listOfObjects",
      child: {
        accountNumber: { type: "text" },
        accountCode: { type: "text" },
        currency: { type: "currencySelect" },
        country: { type: "countrySelect" },
        enabled: { type: "bool" },
      },
    },
  },
  ["e211d399-861f-40b1-9716-b527e4d69e59"]: {
    apiUserName: { type: "text" },
    apiPassword: { type: "text" },
  },
  ["9f7d63ca-3ec8-4a11-b5c9-f3836964f4f7"]: {
    apiKey: { type: "text" },
    shopId: { type: "text" },
  },
  ["7e2cde8f-3e91-4dd9-b68b-a2bb1a548873"]: {
    apiKey: { type: "text" },
    mid: { type: "number" },
    email: { type: "text" },
    password: { type: "text" },
  },
  ["a2488a30-2e6b-4672-bc20-3e97131b049b"]: {
    clientId: { type: "text" },
    clientSecret: { type: "text" },
    ivKey: { type: "text" },
    secretKey: { type: "text" },
  },
  ["85e999d1-5787-46d4-b280-713160278641"]: {
    appId: { type: "text" },
    saltKey: { type: "text" },
  },
  ["8eb950cb-cbd4-40f1-b0b8-5c5011b7aca2"]: {
    companyNum: { type: "text" },
    secretKey: { type: "text" },
  },
  ["f7e1538c-a763-4f35-9c16-b5a336fc42ab"]: {
    companyNum: { type: "text" },
    secretKey: { type: "text" },
  },
  ["a60441c3-4d4b-45b2-9f50-84204f1260ac"]: {
    terminalId: { type: "text" },
    secretKey: { type: "text" },
    apiKey: { type: "text" },
  },
  ["84c61062-14b1-459b-9625-adb98a149e45"]: {
    merchantNumber: { type: "text" },
    secretKey: { type: "text" },
  },
  ["967df429-c93a-45f3-b929-448119ca62d3"]: {
    sid: { type: "text" },
    payoutSid: { type: "text" },
    payoutApiKey: { type: "text", size: "md" },
  },
  ["3a07439b-71f5-427a-bf38-0463323e9e13"]: {
    publicKey: { type: "text" },
    secretKey: { type: "text" },
  },
  ["4fbac749-beba-4c1b-b856-a3ed8fc15032"]: {
    accessToken: { type: "text" },
    campaignId: { type: "text" },
    securityToken: { type: "text" },
  },
  ["c6279cff-02cb-4408-bff8-03ad8c91bcb6"]: {
    applicationUuid: { type: "text" },
    secret: { type: "text" },
    accounts: {
      type: "listOfObjects",
      child: {
        bic: { type: "text" },
        payeeName: { type: "text" },
        country: { type: "countrySelect" },
        currency: { type: "currencySelect" },
        iban: { type: "text" },
        enabled: { type: "bool" },
        address: {
          name: "address",
          type: "object",
          child: {
            country: { type: "countrySelect" },
            city: { type: "text" },
            postCode: { type: "text" },
            addressLine: { type: "text" },
          },
        },
      },
    },
  },
  ["131202e8-b933-49be-9a50-ce7325af2d87"]: {
    apikey: { type: "text" },
  },
  ["ed6d5ab1-c61b-44bb-893c-b8503ee0ebca"]: {
    brandId: { type: "text" },
    secretKey: { type: "text" },
  },
  ["19fd94b3-d8cb-4fc4-ba2d-2c8fbe746877"]: {
    apiKey: { type: "text" },
    secret: { type: "text" },
  },
  ["eb1d099e-cef2-4c85-8d70-e8e544a40277"]: {
    companyNum: { type: "text" },
    secretKey: { type: "text" },
    apiKey: { type: "text" },
  },
  ["766a4a16-fdda-4b33-818b-26b3c56f00ec"]: {
    apiKey: { type: "text" },
    signatureKey: { type: "text" },
    targetUrl: { type: "text" },
  },
  ["dd446ec7-47b8-423f-b29e-dae2e2e8b98e"]: {
    publishable: { type: "text" },
    secret: { type: "text" },
  },
  ["ffbc93e1-c38c-4790-be26-48d770136a81"]: {
    merchantAccount: { type: "text" },
    apiKey: { type: "text" },
    targetUrl: { type: "text" },
  },
  ["00c55e3a-c720-411d-af79-8d7b7459f490"]: {
    accessKey: { type: "text" },
    secretKey: { type: "text" },
    paymentMethods: { type: "listOfTexts" },
  },
  ["856e75bb-5d7e-4213-98e0-48162ec8e0ae"]: {
    clientId: { type: "text" },
    clientSecret: { type: "text" },
  },
  ["cf62279b-401c-4a3b-91d8-a59c59a65148"]: {
    apiKey: { type: "text", size: "lg" },
  },
  ["1395c526-edf2-472f-9e7d-3cd45c03b78c"]: {
    mid: { type: "text" },
    midQ: { type: "text" },
    targetUrl: { type: "text", size: "lg" },
  },
  ["bb2c4c8c-84fc-4e9d-936e-bcb01da2fe44"]: {
    currency: { type: "currencySelect" },
    terminalToken: { type: "text" },
    username: { type: "text" },
    password: { type: "text" },
  },
  ["4c60f035-b19e-4e97-87fe-fe0260faf663"]: {
    appId: { type: "text" },
    secret: { type: "text" },
    accounts: {
      type: "listOfObjects",
      child: {
        iban: { type: "text" },
        currency: { type: "currencySelect" },
        name: { type: "text" },
        countryCode: { type: "countrySelect" },
      },
    },
  },
};

export const sanitizeRequest = (data, pspId) => {
  switch (pspId) {
    case "19fd94b3-d8cb-4fc4-ba2d-2c8fbe746877":
      return {
        apiKey: data.apiKey,
        secret: data.secret,
      };
    case "84c61062-14b1-459b-9625-adb98a149e45":
      return {
        merchantNumber: data.merchantNumber,
        secretKey: data.secretKey,
      };
    case "8eb950cb-cbd4-40f1-b0b8-5c5011b7aca2":
      return {
        companyNum: data.companyNum,
        secretKey: data.secretKey,
      };
    case "f7e1538c-a763-4f35-9c16-b5a336fc42ab":
      return {
        companyNum: data.companyNum,
        secretKey: data.secretKey,
      };
    case "41b589c8-677d-4ef6-91a0-fe188d3fd075":
      return {
        companyNum: data.companyNum,
        secretKey: data.secretKey,
      };
    case "9c58ced7-e128-4b41-9828-232555fa513f":
      return {
        apiKey: data.apiKey,
      };
    case "85e999d1-5787-46d4-b280-713160278641":
      return {
        appId: data.appId,
        saltKey: data.saltKey,
      };
    case "3b0d3fe0-5206-4640-82ad-333308a25780":
      return {
        mid: data.mid,
        serverKey: data.serverKey,
        enabled: data.enabled,
        acquirers: data.acquirers,
      };
    case "d2336afc-1e8c-473b-8182-41cd3ed26529":
      return {
        clientId: data.clientId,
        clientSecret: data.clientSecret,
        enabled: data.enabled,
      };
    case "5d8c9938-3d8e-42fe-8a06-76196b4ddc7b":
      return {
        apiKey: data.apiKey,
        signatureKey: data.signatureKey,
        enabled: data.enabled,
      };
    case "131202e8-b933-49be-9a50-ce7325af2d87":
      return {
        apikey: data.apikey,
      };
    case "e60ebac2-3976-438b-a7ab-4fab89e646a4":
      return {
        merchant: data.merchant,
        callbackApiKey: data.callbackApiKey,
        countryKeys: data.countryKeys,
        contractName: data.contractName,
        enabled: data.enabled,
      };
    case "0063e91f-9b5b-444c-aa6f-ce5674db17ad":
      return {
        externalMerchantId: data.externalMerchantId,
        apiKey: data.apiKey,
        enabled: data.enabled,
      };
    case "71afea8c-7b50-4871-85eb-87ae66982e4d":
      return {
        mid: data.mid,
        apiKey: data.apiKey,
        descriptorName: data.descriptorName,
        descriptorUrl: data.descriptorUrl,
      };
    case "0ea3bc80-eae0-4567-89fb-64ac7faee7f8":
      return {
        username: data.username,
        password: data.password,
        payoutCustomerId: data.payoutCustomerId,
        payoutApiKey: data.payoutApiKey,
        accounts: data.accounts,
        enabled: data.enabled,
      };
    case "c6279cff-02cb-4408-bff8-03ad8c91bcb6":
      return {
        applicationUuid: data.applicationUuid,
        secret: data.secret,
        accounts: data.accounts,
        address: data.address,
      };
    case "e211d399-861f-40b1-9716-b527e4d69e59":
      return {
        apiUserName: data.apiUserName,
        apiPassword: data.apiPassword,
        enabled: data.enabled,
      };
    case "9f7d63ca-3ec8-4a11-b5c9-f3836964f4f7":
      return {
        apiKey: data.apiKey,
        shopId: data.shopId,
        enabled: data.enabled,
      };
    case "7e2cde8f-3e91-4dd9-b68b-a2bb1a548873":
      return {
        apiKey: data.apiKey,
        mid: data.mid,
        email: data.email,
        password: data.password,
        enabled: data.enabled,
      };
    case "a2488a30-2e6b-4672-bc20-3e97131b049b":
      return {
        clientId: data.clientId,
        clientSecret: data.clientSecret,
        ivKey: data.ivKey,
        secretKey: data.secretKey,
        enabled: data.enabled,
      };
    case "a60441c3-4d4b-45b2-9f50-84204f1260ac":
      return {
        terminalId: data.terminalId,
        secretKey: data.secretKey,
        apiKey: data.apiKey,
      };
    case "3a07439b-71f5-427a-bf38-0463323e9e13":
      return {
        publicKey: data.publicKey,
        secretKey: data.secretKey,
        enabled: data.enabled,
      };
    case "4fbac749-beba-4c1b-b856-a3ed8fc15032":
      return {
        accessToken: data.accessToken,
        campaignId: data.campaignId,
        securityToken: data.securityToken,
        enabled: data.enabled,
      };
    case "4269aa7c-cdae-423c-9030-0a794e0be240":
      return {
        payinCredentials: data.payinCredentials,
        payoutCredential: data.payoutCredential,
      };
    case "967df429-c93a-45f3-b929-448119ca62d3":
      return {
        sid: data.sid,
        payoutSid: data.payoutSid,
        payoutApiKey: data.payoutApiKey,
      };
    case "ed6d5ab1-c61b-44bb-893c-b8503ee0ebca":
      return {
        brandId: data.brandId,
        secretKey: data.secretKey,
      };
    case "eb1d099e-cef2-4c85-8d70-e8e544a40277":
      return {
        companyNum: data.companyNum,
        secretKey: data.secretKey,
        apiKey: data.apiKey,
      };
    case "766a4a16-fdda-4b33-818b-26b3c56f00ec":
      return {
        apiKey: data.apiKey,
        signatureKey: data.signatureKey,
        targetUrl: data.targetUrl,
      };
    case "dd446ec7-47b8-423f-b29e-dae2e2e8b98e":
      return {
        publishable: data.publishable,
        secret: data.secret,
      };
    case "ffbc93e1-c38c-4790-be26-48d770136a81":
      return {
        merchantAccount: data.merchantAccount,
        apiKey: data.apiKey,
        targetUrl: data.targetUrl,
      };
    case "00c55e3a-c720-411d-af79-8d7b7459f490":
      return {
        accessKey: data.accessKey,
        secretKey: data.secretKey,
        paymentMethods: data.paymentMethods,
      };
    case "856e75bb-5d7e-4213-98e0-48162ec8e0ae":
      return {
        clientId: data.clientId,
        clientSecret: data.clientSecret,
      };
    case "cf62279b-401c-4a3b-91d8-a59c59a65148":
      return {
        apiKey: data.apiKey,
      };
    case "1395c526-edf2-472f-9e7d-3cd45c03b78c":
      return {
        mid: data.mid,
        midQ: data.midQ,
        targetUrl: data.targetUrl,
      };
    case "bb2c4c8c-84fc-4e9d-936e-bcb01da2fe44":
      return {
        username: data.username,
        password: data.password,
        terminalToken: data.terminalToken,
        currency: data.currency,
      };
    case "4c60f035-b19e-4e97-87fe-fe0260faf663":
      return {
        appId: data.appId,
        secret: data.secret,
        accounts: data.accounts,
      };
    default:
      return data;
  }
};
