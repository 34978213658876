import { Box, Divider, Grid } from "@mui/material";
import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import {
  MultipleSelectField,
  SelectInputField,
  TextInputField,
} from "../../../../../components/FormField";
import { countriesCode } from "../../../../../constants/countriesCode";
import { useGetUserRoles } from "pages/settings/users/hooks";
import { LoginTypes } from "constants/roles";
import { generateOptions } from "utils/options";
import { useGetMerchantsByOrg } from "pages/merchant-management/hooks";

export const sanitizeRequest = ({
  id,
  name,
  legalName,
  registrationNumber,
  website,
  companyPhone,
  companyEmail,
  companyAddress,
  country,
  currency,
  mccCode,
  vat,
  cardBaseCurrency,
  paymentFac,
  code,
}) => {
  const filteredPayload = _.pickBy(
    {
      id,
      name,
      legalName,
      registrationNumber,
      website,
      companyPhone,
      companyEmail,
      companyAddress,
      country,
      currency,
      mccCode,
      vat,
      code: code,
      cardBaseCurrency,
    },
    _.identity
  );
  return {
    ...filteredPayload,
    paymentFac,
  };
};
const UserForm = ({ organizationId }) => {
  const { data: roles } = useGetUserRoles({ loginType: LoginTypes.ORGANIZATION });
  const { data: merchants } = useGetMerchantsByOrg(organizationId);

  const rolesOptions = generateOptions(roles, false);
  const merchantOptions = generateOptions(merchants, false);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Name" name="name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Surname" name="surname" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Email" name="email" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container display="flex" width={"100%"}>
            <Grid item xs={4}>
              <SelectInputField
                name="code"
                label="Code"
                options={_.map(countriesCode, (option) => ({
                  label: `${option.label} +${option.phone}`,
                  value: `+${option.phone}`,
                }))}
                placeholder="Select an option"
              />
            </Grid>
            <Grid item xs={8} pl={2}>
              <TextInputField label="Phone" name="phone" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
          <MultipleSelectField
            name="roles"
            label="Roles"
            options={rolesOptions}
            placeholder="Select an option"
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ marginBottom: 2 }}>
          <MultipleSelectField
            name="merchants"
            label="Merchants"
            options={merchantOptions}
            placeholder="Select merchants"
          />
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

UserForm.propTypes = {
  organizationId: PropTypes.string,
};

export default UserForm;
