import { useMutation, useQuery, useQueryClient } from "react-query";
import { getTemplatePath } from "../../../utils/string";
import request from "../../../utils/request";
import { toastSuccess } from "../../../utils/toast";

const GET_MANAGE_USERS = "/admin-users";
const CREATE_MANAGE_USERS = "/admin-users";
const DELETE_MANAGE_USERS = "/admin-users/:id";

const RESET_USER_PASSWORD = "/auth/forgot-password";
const ROLES = "/auth/roles";
const ROLE = "/auth/role";
const ROLES_BY_LOGIN_TYPE = `${ROLES}/:loginType`;
const DELETE_ROLE = `${ROLE}/:id`;
const UPDATE_ADMIN_USER = "/admin-users/:id";
const UPDATE_ORG_USER = "/organizations/:organizationId/users/:id";
const PERMISSIONS = "/auth/get-all-permissions";

const getManageUsers = () => {
  return request.get(getTemplatePath(GET_MANAGE_USERS));
};

const createManageUser = (payload) => {
  return request.post(CREATE_MANAGE_USERS, payload);
};

const deleteManageUser = ({ id }) => {
  return request.delete(getTemplatePath(DELETE_MANAGE_USERS, { id }));
};
const resetUserPassword = (payload) => request.post(RESET_USER_PASSWORD, payload);

export const useGetManageUsers = () => {
  return useQuery([GET_MANAGE_USERS], getManageUsers);
};
export const useCreateManageUser = () => {
  const queryClient = useQueryClient();
  return useMutation(createManageUser, {
    onSuccess: () => {
      toastSuccess({ description: "Create user has been success" });
      queryClient.invalidateQueries([GET_MANAGE_USERS]);
    },
  });
};

export const useDeleteManageUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteManageUser, {
    onSuccess: () => {
      toastSuccess({ description: "User deleted successfully" });
      queryClient.invalidateQueries([GET_MANAGE_USERS]);
    },
  });
};

export const useResetUserPassword = () => {
  const queryClient = useQueryClient();
  return useMutation(resetUserPassword, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_MANAGE_USERS]);
    },
  });
};

// get user roles
export const useGetUserRoles = ({ loginType }) => {
  return useQuery([ROLES_BY_LOGIN_TYPE], () => {
    const URL = getTemplatePath(ROLES_BY_LOGIN_TYPE, { loginType });
    return request.get(URL);
  });
};

// update user roles
const updateAdminUser = ({ roles, id }) => {
  const URL = getTemplatePath(UPDATE_ADMIN_USER, { id });
  return request.patch(URL, { roles });
};
export const useUpdateAdminUser = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAdminUser, {
    onSuccess: () => {
      toastSuccess({ description: "Update admin user has been success" });
      queryClient.invalidateQueries([GET_MANAGE_USERS]);
    },
  });
};

// update user roles
const updateOrganizationUser = ({ roles, merchants, id, organizationId }) => {
  const URL = getTemplatePath(UPDATE_ORG_USER, { id, organizationId });
  return request.patch(URL, { roles, merchants });
};
export const useUpdateOrganizationUser = () => {
  const queryClient = useQueryClient();
  return useMutation(updateOrganizationUser, {
    onSuccess: () => {
      toastSuccess({ description: "Update organization user has been success" });
      queryClient.invalidateQueries([GET_MANAGE_USERS]);
    },
  });
};

// get all roles
export const useGetRoles = () => {
  return useQuery([ROLES], () => {
    return request.get(ROLES);
  });
};

// create role
const createRole = (payload) => {
  return request.post(ROLE, payload);
};
export const useCreateRole = () => {
  const queryClient = useQueryClient();
  return useMutation(createRole, {
    onSuccess: () => {
      toastSuccess({ description: "Create role has been success" });
      queryClient.invalidateQueries([ROLES]);
    },
  });
};

// create role
const updateRole = (payload) => {
  return request.put(ROLE, payload);
};
export const useUpdateRole = () => {
  const queryClient = useQueryClient();
  return useMutation(updateRole, {
    onSuccess: () => {
      toastSuccess({ description: "Update role has been success" });
      queryClient.invalidateQueries([ROLES]);
    },
  });
};

// delete role
const deleteRole = ({ id }) => {
  const URL = getTemplatePath(DELETE_ROLE, { id });
  return request.delete(URL);
};
export const useDeleteRole = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteRole, {
    onSuccess: () => {
      toastSuccess({ description: "Delete role has been success" });
      queryClient.invalidateQueries([ROLES]);
    },
  });
};

// all routers
export const useGetPermissions = () => {
  return useQuery([PERMISSIONS], () => {
    return request.get(PERMISSIONS);
  });
};
